.how-to-play-container {
  width: 100%;
  max-width: 800px;
  margin: 2rem auto 2rem auto;
  border-radius: 8px;
  padding: 2rem;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
}

.top-blocks-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.top-blocks-row.row-even .block {
  background: #0000005e;
}

.head-block {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 1rem;
  font-weight: bold;
  text-align: center;
}

.block {
  background: #141516;
  border: 1px solid #212527;
  border-radius: 8px;
  padding: 1rem;
  flex: 1;
  margin: 0 0.5rem;
}

.block h2 {
  margin-top: 0;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.block p {
  font-size: 1rem;
  line-height: 1.5;
  opacity: 0.7;
}

.alert-box {
  background-color: #fef3c7;
  border: 1px solid #facc15;
  border-radius: 8px;
  padding: 0.75rem;
  margin-top: 1rem;
  color: #000;
  font-size: 0.9rem;
  text-align: center;
}

.block3 {
  display: flex;
  align-items: center;
  position: relative;
  padding: 1rem;
  background: #141516;
  border: 1px solid #212527;
  border-radius: 8px;
}

.block3-text {
  // flex: 1;
}

.block3-image {
  width: 50%;
  position: relative;
}

.block3-image img {
  width: 100%;
  display: block;
  position: absolute;
  right: -40px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #2c2d2d;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .how-to-play-container {
    width: 100%;
    padding: 1rem;
    margin: 2rem auto;
    margin-top: 0;
  }

  .head-block {
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: auto;
    margin-bottom: 0.5rem;
  }

  .top-blocks-row {
    flex-direction: column;
    margin-bottom: 0rem;
  }

  .block {
    margin: 0.5rem 0;
  }

  .block3 {
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }

  .block3-image {
    position: relative;
    width: 100%;
    margin-top: 1rem;
  }

  .block3-image img {
    position: relative;
    right: 0;
    top: 0;
    transform: none;
    width: 100%;
  }
}
