.leaderboard-dropdown {
  position: relative;
  width: 100%;
  font-family: "Terminus", monospace;
  color: #fff;

  .leaderboard-dropdown-toggle {
    width: 100%;
    background-color: #2a2a2a;
    border: 1px solid #444;
    border-radius: 4px;
    padding: 0.75rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    img {
      width: 24px;
      height: 24px;
      margin-right: 0.75rem;
      flex-shrink: 0;
    }

    .leaderboard-option-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .leaderboard-dropdown-menu {
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(100% + 4px);
    background-color: #2a2a2a;
    border: 1px solid #444;
    border-radius: 4px;
    padding: 0.5rem 0;
    z-index: 10;
    max-height: 300px;
    overflow-y: auto;

    .leaderboard-dropdown-item {
      display: flex;
      align-items: center;
      padding: 0.75rem 1rem;
      cursor: pointer;
      transition: background-color 0.2s ease;

      img {
        width: 24px;
        height: 24px;
        margin-right: 0.75rem;
        flex-shrink: 0;
      }

      .leaderboard-option-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:hover {
        background-color: #3a3a3a;
      }
    }
  }
}
