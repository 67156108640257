.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
  background-color: #1a1a1a;
  border: 1px solid #272b30;
  border-radius: 8px;
  color: #ffffff;
  width: 400px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-title.buy-in {
  font-weight: bold;
}

.modal-descritption.buy-in {
  color: #a5a5a5;
}

.buyInField {
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  width: 100%;
  margin: 1em 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  overflow: hidden;
}

.buyInInfoGroup {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 5px;
  position: relative;
}

.token-image {
  width: 20px;
  height: auto;
  margin-right: 8px;
  flex-shrink: 0;
}

.token-symbol {
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.BuyInText {
  flex-grow: 1;
  flex-shrink: 0;
  margin: 0 10px;
  color: black;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1rem;
  font-family: "Terminus", monospace;
  text-align: right;
  overflow: hidden;
  user-select: none;
}

.buyInSlider {
  width: 100%;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  width: 100%;
}

.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 1em;
}

.cancel-button,
.buyin-button {
  width: 40%;
  padding: 0.5em 1em;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: 1rem;
  transition:
    background-color 0.25s ease-in-out,
    border 0.25s ease-in-out,
    box-shadow 0.25s ease-in-out;
}

.buyin-button {
  color: #14be14;
  border: 1px solid #14be14;
  background: #14be141a;
}

.cancel-button {
  color: #fff;
  border: 1px solid #fff;
  background: #fff1;
}

.cancel-button:hover,
.buyin-button:hover {
  opacity: 0.8;
  box-shadow: none;
}

.cancel-button:disabled,
.buyin-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.status-message {
  margin-top: 1em;
  font-size: 1rem;
  text-align: center;
  font-family: "Terminus", monospace;
}

.balance-warning {
  background-color: #fff4f4;
  border: 1px solid #ffcccc;
  color: #cc0000;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 12px;
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.balance-warning a {
  text-decoration: underline;
  color: #000;
}