.dropdown-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    height: fit-content;
}

.dropdown-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
    background-color: black;
    color: white;
    opacity: 0.5;
}

.dropdown-item.selected {
    opacity: 1.0;
}

.indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

.indicator.red {
    background-color: red;
}

.indicator.green {
    background-color: green;
}