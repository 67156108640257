.profile-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .divider {
    border-color: #333;
  }
}

.profile-container {
  background-color: #16171b;
  min-width: 600px;
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.profile-tabs {
  display: flex;
  gap: 1rem;

  button {
    background: #2a2a2a;
    border: none;
    border-radius: 4px;
    color: #fff;
    padding: 0.6rem 1.2rem;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
      background: #3a3a3a;
    }

    &.active {
      background: #ff4d50;
    }
  }
}

.profile-content {
  /* color: #fff; */
}

.general-tab {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .input-group {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .input-label {
    font-size: 0.85rem;
    color: #bbb;
    text-align: left;
    margin-left: 5px;
    font-weight: 500;
    margin-bottom: -1rem;
  }
  

  .row-inline {
    display: flex;
    align-items: center;
    gap: 1rem;

    input[type="text"] {
      flex: 1;
      background-color: #2a2a2a;
      border: 1px solid #444;
      border-radius: 4px;
      padding: 0.5rem 1rem;
      color: #fff;

      &:focus {
        outline: none;
        border-color: #666;
      }
    }
  }

  .referral-row {
    label {
      width: 100px;
      text-align: right;
      margin-right: 0.5rem;
      font-weight: 500;
    }
    input {
      max-width: auto;
      text-align: left;
    }
  }

  .btn-save,
  .btn-copy,
  .btn-create-referral {
    background: #0055ff;
    border: none;
    border-radius: 4px;
    color: #fff;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-weight: 500;
    transition: background 0.2s;

    &:hover {
      background: #0040cc;
    }
  }

  .info-box {
    background-color: #fef3c7;
    border: 1px solid #facc15;
    border-radius: 8px;
    padding: 0.75rem;
    color: #000;
    font-size: 0.9rem;
    text-align: left;
  }

  .info-box a {
    text-decoration: underline;
    cursor: pointer;
  }

  .post-on-x-button {
    color: gold;
  }
  .post-on-x-button img {
    width: 24px;
    display: inline-block;
  }
}

.quests-tab {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .quest-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #2a2a2a;
    padding: 0.75rem 1rem;
    border-radius: 4px;

    span {
      color: #ddd;
      font-size: 0.95rem;
    }

    button {
      background: #444;
      color: #fff;
      border: none;
      border-radius: 4px;
      padding: 0.4rem 0.8rem;
      font-weight: 500;
      cursor: pointer;
      transition: background 0.2s;

      &:hover {
        background: #555;
      }

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }
}

.admin-tab {
  p {
    color: #ccc;
    font-size: 1rem;
  }

  .btn-save,
  .btn-copy,
  .btn-create-referral {
    background: #0055ff;
    border: none;
    border-radius: 4px;
    color: #fff;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-weight: 500;
    transition: background 0.2s;

    &:hover {
      background: #0040cc;
    }
  }

  .collapsible-panel {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .panel-header {
    background-color: #a1a1a1;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #000;
  }
  
  .panel-content {
    padding: 10px;
    background-color: #b1b1b1;
  }
  
  .game-details img {
    max-width: 100px;
    height: auto;
    display: block;
    margin-top: 10px;
  }
  
  .game-details p{
    color: #000;
  }
}
