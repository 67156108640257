.menu-session {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #fff;

  .session-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2a2a2a;
    border-radius: 4px;

    .session-label {
      font-weight: 600;
      font-size: 1rem;
    }

    .session-balance {
      font-weight: 500;
    }
  }

  .session-bottom {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    background-color: #2a2a2a;
    border-radius: 4px;
    padding: 1rem;

    .session-address {
      background-color: #1b1b1b;
      border: 1px solid #444;
      border-radius: 4px;
      color: #fff;
      padding: 0.5rem 1rem;
      width: 100%;
      margin-bottom: 0.5rem;
    }

    .session-buttons {
      display: flex;
      gap: 1rem;

      button {
        background-color: #0055ff;
        border: none;
        border-radius: 4px;
        color: #fff;
        padding: 0.5rem 1rem;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          background-color: #0040cc;
        }
      }

      .btn-fund {
      }

      .btn-withdraw {
        background: transparent;
        border: 1px solid #fff;

        &:hover {
          background-color: #ffffff2b;
        }
      }
    }
  }
}
