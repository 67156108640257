.create-game-wrapper {
  display: flex;
  flex-direction: column;
  height: 84vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.create-game-card {
  padding: 2vw;
  width: 60vw;
  max-width: 1000px;
  background-color: #16171b;
  border: 1px solid #272b30;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 0.75rem;
}

.tab-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.tab-dot {
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  margin: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #fff;
  background-color: #000;
  transition: all 0.3s;
}

.tab-dot:hover {
  border-color: #000;
  background-color: #fff;
}

.tab-dot.active {
  background-color: #fff;
  border: 1px solid #000;
}
