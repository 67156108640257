.main-container {
  background-image: radial-gradient(56.1514% 56.1514% at 49.972% 38.959%, rgb(39, 54, 73) 0%, rgb(0, 0, 0) 100%);
  height: 100vh;
  overflow-y: scroll;
}

.home-container {
  margin: 5rem auto;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  max-width: 1000px;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: fit-content;
}

.filters-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .search-game-input {
    background-color: #2a2a2a;
    border: 1px solid #444;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    color: #fff;
    width: 200px;
    margin-right: 10px;

    &::placeholder {
      color: #aaa;
    }

    &:focus {
      outline: none;
      border-color: #666;
    }
  }

  .header-buttons {
    display: flex;
    gap: 1rem;
  }
}

.home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-buttons {
    display: flex;
    gap: 1rem;
  }
}

.btn-outlined {
  background: transparent;
  border: 2px solid currentColor;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition:
    background-color 0.2s,
    color 0.2s;

  &:hover {
    opacity: 0.5;
    color: #fff;
  }

  &.btn-orange {
    color: #ffa500;
  }

  &.btn-green {
    color: #00d37d;
    background: #70ca6d17;
  }
}

.table-container {
  background-color: #16171b;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  border-radius: 8px;
  overflow-x: auto;
  width: fit-content;
}

.lobby-table {
  border-collapse: collapse;
  min-width: 700px;

  thead {
    background-color: #2f2f2f;
    th {
      text-align: center;
      padding: 0.75rem 1rem;
      font-weight: 600;
      border-bottom: 1px solid #444;
      background-color: #2a2a2a;
    }
    // // second column with Token logo/name must be left aligned
    // th:nth-child(2) {
    //   text-align: left;
    // }
  }

  tbody {
    tr {
      &:nth-of-type(even) {
        background-color: #262626;
      }
      &:hover {
        background-color: #333;
      }
      td {
        padding: 0.75rem 1rem;
        border-bottom: 1px solid #444;
        vertical-align: middle;
        text-align: center;
      }
      // 3rd column with Token logo/name must be left aligned
      td:nth-child(3) {
        text-align: left;
      }
    }
  }
}

.community-list {
  padding: 5px 10px;
  border-radius: 20px;
  color: #000;
  font-size: 12px;

  background-image: linear-gradient(
    95deg,
    #dcdcdc -20.77%,
    #c0c0c0 3.29%,
    #b0b0b0 20.39%,
    #d3d3d3 37.13%,
    #a9a9a9 85.19%,
    #e0e0e0 108.34%,
    #f5f5f5 134.49%
  );
}

.strict-list {
  padding: 5px 10px;
  border-radius: 20px;
  color: #000;
  font-size: 12px;

  background-image: linear-gradient(
    95deg,
    #ffffb7 -20.77%,
    #ffe297 3.29%,
    #f8d57b 20.39%,
    #ffeeb4 37.13%,
    #f0c34e 85.19%,
    #feeab7 108.34%,
    #fefff1 134.49%
  );
}

.region-buttons {
  background-color: #3333335c;
  border-radius: 8px;
  padding: 0.5rem 0.5rem;
}
.region-button {
  border-radius: 8px;
}
.ping-cell {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .ping-circle {
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
  }
}

.btn-play {
  width: 120px;
  background-color: #0077ff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: #005ec7;
  }

  &[disabled] {
    background-color: #404040;
    cursor: not-allowed;
  }
}

.refresh-icon {
  animation: spin 1s linear infinite;
}

img.token-image {
  width: 36px;
  height: 36px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #ffffff5c;
  padding: 2px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mobile-only {
  display: none;
}

@media (max-width: 768px) {
  .desktop-only {
    display: none !important;
  }

  .mobile-only {
    display: block;
  }
}

@media (max-width: 768px) {
  .home-container {
    margin-top: 0;
  }

  .table-container {
    width: 100%;
  }

  .lobby-table {
    min-width: 100%;
  }

  .lobby-table thead {
    display: none;
  }

  .lobby-table tbody tr {
    display: block;
    border: 1px solid #444;
    border-radius: 8px;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    background-color: #141516;
  }

  .lobby-table tbody tr th {
    text-align: left;
    color: #fff;
  }

  .lobby-table tbody tr td {
    color: #fff;
    display: block;
    width: 100%;
    border: none;
    padding: 0.5rem;
    position: relative;
    font-size: 0.9rem;
    text-align: left;
  }

  .lobby-table tbody tr td:nth-of-type(1)::before {
    content: "Game ID: ";
    font-weight: bold;
    display: inline-block;
    width: 35%;
  }
  .lobby-table tbody tr td:nth-of-type(2)::before {
    content: "Creator: ";
    font-weight: bold;
    display: inline-block;
    width: 35%;
  }
  .lobby-table tbody tr td:nth-of-type(3)::before {
    content: "Token: ";
    font-weight: bold;
    display: inline-block;
    width: 35%;
  }
  .lobby-table tbody tr td:nth-of-type(4)::before {
    content: "Buy In: ";
    font-weight: bold;
    display: inline-block;
    width: 35%;
  }
  .lobby-table tbody tr td:nth-of-type(5)::before {
    content: "Players: ";
    font-weight: bold;
    display: inline-block;
    width: 35%;
  }

  .tooltip-container.desktop-only {
    display: none !important;
  }

  .ping-cell {
    display: inline-block;
  }

  .btn-play {
    width: 100%;
    background-color: #0077ff87;
  }

  .mobile-alert {
    text-align: center;
    background: #00c5ff40;
    padding: 10px;
    border-radius: 8px;
  }
}
