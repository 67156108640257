.footerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  font-family: "Terminus", sans-serif;
}

.footerIcons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.footerIcon {
  position: relative;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.footerIconImg {
  position: absolute;
  width: 20px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.8;
  transition: opacity 0.3s;
}

.footerIconImgHover {
  opacity: 0;
  transition: opacity 0.3s;
}

.footerIcon:hover .footerIconImg {
  opacity: 0.2;
}

.footerIcon:hover .footerIconImgHover {
  opacity: 0.8;
}

.footerCopyright {
  color: #fff;
}
