.game-leaderboard {
  position: absolute;
  padding: 10px;
  color: #fff;
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: 16.1px;
  top: 10px;
  right: 10px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid gray;
  z-index: 9999;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.game-leaderboard .title {
  font-size: 18px;
  margin-bottom: 8px;
}

.game-leaderboard ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.game-leaderboard li {
  margin-bottom: 4px;
}

.game-leaderboard li.me {
  color: #67b11a;
}
