.exitButton {
  font-family: "Open Sans", "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
  color: black;
  text-decoration: none;
  background: #f07171;
  float: right;
  border: 1px solid #f07171;
  border-radius: 100px;
  padding: 6px 10px;
  transition:
    background 0.3s ease 0s,
    color 0.3s ease 0s;
  z-index: 9999999;
}
.exitButton:active,
.exitButton:hover {
  background: black;
  color: #f07171;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.checkmark {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #15bd12;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #15bd12;
  fill: none;
  animation:
    fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #15bd12;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  stroke: white;
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

#returnButton {
  font-family: "Open Sans", "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
  color: black;
  text-decoration: none;
  background: white;
  float: right;
  border: 1px solid white;
  border-radius: 100px;
  padding: 10px 15px;
  margin: 10px;
  transition:
    background 0.3s ease 0s,
    color 0.3s ease 0s;
}
#returnButton:active,
#returnButton:hover {
  background: black;
  color: white;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #15bd12;
  }
}
