.menu-wallet {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wallet-connected {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border: 1px solid #ff4d50;
  border-radius: 8px;
}

.wallet-info {
  font-weight: 500;
  font-size: 1rem;
}

.wallet-disconnect-button {
  background-color: #ff4d4f;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #ffffff;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
}

.wallet-disconnect-button .logout-icon {
  width: 16px;
}

.wallet-disconnected {
  position: relative;
}

.connect-wallet-button {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 8px;
  color: #fff;
  /* padding: 0.5rem 1rem; */
  cursor: pointer;
}

.wallet-drawer-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.wallet-drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 280px;
  height: 100%;
  background-color: #1e1e1e;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  animation: slideIn 0.3s ease forwards;
}

.wallet-drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #333;
}

.wallet-drawer-body {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.wallet-drawer-body button {
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
  text-align: left;
  background-color: #2a2a2a;
  color: #ffffff;
  border: 1px solid #444;
  padding: 0.75rem 1rem;
  cursor: pointer;
}

.wallet-drawer-body button img {
  width: 32px;
  border-radius: 5px;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
